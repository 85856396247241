<template>
    <div class="hc17Box" style="text-align: left">
        <div class="hbBox1" v-if="getAdvData(18).children" :style="`background-image: url(${getAdvData(18).children[0].image});`">
            <a :href="getAdvData(18).children[0].url" target="_blank" rel="noopener noreferrer">
                <div class="h1Box">
                    <div class="hbText">
                        <div class="ht1">智创革新&nbsp;&nbsp;&nbsp;&nbsp;开启移动<span>营销未来</span></div>
                        <p class="ht2">威有科技H5手机网站建设服务，以技术实现平台革新，打开移动营销新格局</p>
                    </div>
                    <div class="hbImg">
                        <img :src="getAdvData(18).children[0].children[0].image" />
                    </div>
                </div>
            </a>
        </div>
        <div class="hbBox2">
            <div class="hb2Top">威有手机端开发优势</div>
            <div class="hb2List">
                <ul>
                    <li class="hb2Li" v-for="item in 3" :key="item">
                        <div class="hlBox">
                            <p class="hlb1">威有H5活动站定制</p>
                            <p class="hlb2">vue3新技术</p>
                            <p class="hlb2">PHP后台对接</p>
                            <p class="hlb2">PC+手机网页同时开发</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="hbBox3">
            <div class="hb3Top">
                <p class="h3t1">威有H5活动站定制</p>
                <p class="h3t2">威有科技以全方位营销思维，打造H5活动站的“三多”优势</p>
            </div>
            <div class="hb3Bg">
                <div class="hb3List">
                    <ul>
                        <li class="hb3Li">
                            <div class="h3Box">
                                <p class="h31">活动多类型</p>
                                <div class="h32">
                                    <dl>
                                        <dt>
                                            <div class="dtImg">
                                                <img src="http://iv.okvu.cn/vugw/img/hc1731.png" alt="" class="aiIcon" />
                                            </div>
                                            <p class="dtp">广告宣传</p>
                                        </dt>
                                        <dt>
                                            <div class="dtImg">
                                                <img src="http://iv.okvu.cn/vugw/img/hc1732.png" alt="" class="aiIcon" />
                                            </div>
                                            <p class="dtp">互动游戏</p>
                                        </dt>
                                        <dt>
                                            <div class="dtImg">
                                                <img src="http://iv.okvu.cn/vugw/img/hc1733.png" alt="" class="aiIcon" />
                                            </div>
                                            <p class="dtp">线下活动</p>
                                        </dt>
                                        <dt>
                                            <div class="dtImg">
                                                <img src="http://iv.okvu.cn/vugw/img/hc1734.png" alt="" class="aiIcon" />
                                            </div>
                                            <p class="dtp">3D全景展示</p>
                                        </dt>
                                    </dl>
                                </div>
                            </div>
                        </li>
                        <li class="hb3Li">
                            <div class="h3Box">
                                <p class="h31">传播多渠道</p>
                                <div class="h32">
                                    <dl>
                                        <dt>
                                            <div class="dtImg">
                                                <img src="http://iv.okvu.cn/vugw/img/hc1735.png" alt="" class="aiIcon" />
                                            </div>
                                            <p class="dtp">公众号推送</p>
                                        </dt>
                                        <dt>
                                            <div class="dtImg">
                                                <img src="http://iv.okvu.cn/vugw/img/hc1736.png" alt="" class="aiIcon" />
                                            </div>
                                            <p class="dtp">微信群推广</p>
                                        </dt>
                                        <dt>
                                            <div class="dtImg">
                                                <img src="http://iv.okvu.cn/vugw/img/hc1737.png" alt="" class="aiIcon" />
                                            </div>
                                            <p class="dtp">扫描二维码</p>
                                        </dt>
                                        <dt>
                                            <div class="dtImg">
                                                <img src="http://iv.okvu.cn/vugw/img/hc1738.png" alt="" class="aiIcon" />
                                            </div>
                                            <p class="dtp">活动页展示</p>
                                        </dt>
                                        <dt>
                                            <div class="dtImg">
                                                <img src="http://iv.okvu.cn/vugw/img/hc1739.png" alt="" class="aiIcon" />
                                            </div>
                                            <p class="dtp">微博传播</p>
                                        </dt>
                                    </dl>
                                </div>
                            </div>
                        </li>
                        <li class="hb3Li">
                            <div class="h3Box">
                                <p class="h31">营销多收获</p>
                                <div class="h32">
                                    <dl>
                                        <dt class="dt3">
                                            <div class="dt3Img">
                                                <img src="http://iv.okvu.cn/vugw/img/hc1740.png" alt="" class="aiIcon" />
                                            </div>
                                            <p class="dt3p">
                                                互动型展现，吸引<br />
                                                用户眼球
                                            </p>
                                        </dt>
                                        <dt class="dt3">
                                            <div class="dt3Img">
                                                <img src="http://iv.okvu.cn/vugw/img/hc17401.png" alt="" class="aiIcon" />
                                            </div>
                                            <p class="dt3p">
                                                病毒式营销，快速<br />
                                                渗透传播
                                            </p>
                                        </dt>
                                        <dt class="dt3">
                                            <div class="dt3Img">
                                                <img src="http://iv.okvu.cn/vugw/img/hc17402.png" alt="" class="aiIcon" />
                                            </div>
                                            <p class="dt3p">
                                                娱乐化宣传，塑造 <br />
                                                品牌形象
                                            </p>
                                        </dt>
                                    </dl>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hbBox4">
            <div class="hb4Top">
                <p class="h4t1">解决方案</p>
                <p class="h4t2">威有科技以专业的服务态度，将市面上现有APP归纳为类别为企业客户提供具针对性的开发解决方案</p>
            </div>
            <div class="hb4List">
                <ul>
                    <li class="hb4Li" @mousemove="showMove(1)" @mouseleave="showLea(1)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon1 === 1" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img" />
                                <img v-show="showIcon1 === 2" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img12" />
                            </div>
                            <p class="hb2">家政</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(2)" @mouseleave="showLea(2)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon2 === 1" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img2" />
                                <img v-show="showIcon2 === 2" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img22" />
                            </div>
                            <p class="hb2">服饰</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(3)" @mouseleave="showLea(3)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon3 === 1" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img3" />
                                <img v-show="showIcon3 === 2" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img32" />
                            </div>
                            <p class="hb2">美容</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(4)" @mouseleave="showLea(4)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon4 === 1" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img4" />
                                <img v-show="showIcon4 === 2" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img42" />
                            </div>
                            <p class="hb2">外卖</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(5)" @mouseleave="showLea(5)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon5 === 1" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img5" />
                                <img v-show="showIcon5 === 2" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img52" />
                            </div>
                            <p class="hb2">餐饮</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(6)" @mouseleave="showLea(6)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon6 === 1" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img6" />
                                <img v-show="showIcon6 === 2" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img62" />
                            </div>
                            <p class="hb2">直播</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(7)" @mouseleave="showLea(7)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon7 === 1" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img7" />
                                <img v-show="showIcon7 === 2" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img72" />
                            </div>
                            <p class="hb2">快递</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(8)" @mouseleave="showLea(8)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon8 === 1" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img8" />
                                <img v-show="showIcon8 === 2" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img82" />
                            </div>
                            <p class="hb2">医疗</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(9)" @mouseleave="showLea(9)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon9 === 1" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img9" />
                                <img v-show="showIcon9 === 2" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h1Img92" />
                            </div>
                            <p class="hb2">理财</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(10)" @mouseleave="showLea(10)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img
                                    v-show="showIcon10 === 1"
                                    src="http://iv.okvu.cn/vugw/img/vu818.png"
                                    alt=""
                                    class="h1Img10"
                                />
                                <img
                                    v-show="showIcon10 === 2"
                                    src="http://iv.okvu.cn/vugw/img/vu818.png"
                                    alt=""
                                    class="h1Img102"
                                />
                            </div>
                            <p class="hb2">旅游</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="hbBox5">
            <div class="hb5Top">客户案例</div>
            <div class="hb5List">
                <ul>
                    <li
                        class="hb5Li wow animate__animated animate__slideInUp"
                        v-for="item in list"
                        :key="item.id"
                        @click.prevent="goDetail(item)"
                    >
                        <img v-lazy="item.little_image" />
                        <div class="hb5Mask" v-if="item.qrcode || item.logo">
                            <img class="hlCode" v-if="item.qrcode" v-lazy="item.qrcode" />
                            <img class="hlCode" v-else v-lazy="item.logo" />
                            <!-- <p class="hb5Text">{{ item.title }}</p> -->
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="hbBox6">
            <img src="http://iv.okvu.cn/vugw/img/hc1761.png" alt="" class="h6Top wow animate__animated animate__slideInDown" />
            <img src="http://iv.okvu.cn/vugw/img/hc2333.png" alt="" class="hb6Sun" />
            <img src="http://iv.okvu.cn/vugw/img/hc1764.png" alt="" class="hb6Yun" />
            <div class="hb6Top">开发优势</div>
            <div class="hb6Box">
                <ul>
                    <li class="hb6Li" v-for="item in 4" :key="item">
                        <p class="hl1">技术实力</p>
                        <div class="hl2">
                            <img class="h2Img" src="http://iv.okvu.cn/vugw/img/hc1765.png" alt="" />
                        </div>
                        <div class="hb6T">
                            <p class="htp">
                                6互联网服务经验，20000+企业客户共同选择。 以不断追求精进的技术理念，
                                创造出成熟的标准化开发流程。确保客户在这里能 够享受到更专业，更优质的服务。
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <img src="http://iv.okvu.cn/vugw/img/hc1762.png" alt="" class="h6l1" />
            <img src="http://iv.okvu.cn/vugw/img/hc1763.png" alt="" class="h6l2" />
            <img src="http://iv.okvu.cn/vugw/img/hc1763.png" alt="" class="h6r1" />
            <div class="hb6B"></div>
        </div>
        <div class="hbBox7">
            <div class="hb7Top">开发流程</div>
            <img src="http://iv.okvu.cn/vugw/img/hc1771.png" alt="" class="hb7Bg wow animate__animated animate__slideInUp" />
            <div class="hb7Box">
                <ul class="toRight">
                    <li class="hb7Li" v-for="item in 8" :key="item" @mouseenter="stop(item)" @mouseleave="start">
                        <div class="hl1" v-if="item % 2" :style="{ opacity: hoverItem == item ? 0 : 1 }">
                            <i class="el-icon-edit"></i>
                        </div>
                        <p class="hl2" :style="{ color: item % 2 ? 'white' : 'black' }">评估需求</p>
                        <div class="hl1" v-if="!(item % 2)" :style="{ opacity: hoverItem == item ? 0 : 1 }">
                            <i class="el-icon-edit"></i>
                        </div>
                        <div class="hlT" :style="{ opacity: hoverItem == item ? 1 : 0 }" :class="{ xia: !(item % 2) }">
                            <div class="ht1"><i class="el-icon-edit"></i></div>
                            <div class="ht2">需求收集、定位分析、可行性分析</div>
                        </div>
                    </li>
                </ul>
            </div>
            <img src="http://iv.okvu.cn/vugw/img/icon_51.png" class="hb7Bg2" alt="" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "HsContent17",
    inject: ["getAdvData"],
    data() {
        return {
            showIcon1: 1,
            showIcon2: 1,
            showIcon3: 1,
            showIcon4: 1,
            showIcon5: 1,
            showIcon6: 1,
            showIcon7: 1,
            showIcon8: 1,
            showIcon9: 1,
            showIcon10: 1,
            setInt: "",
            hoverItem: 1,
            timer: null,
            list: [],
        };
    },
    async created() {
        await this.getData();
        this.$nextTick(() => {
            this.$AOS.init({
                offset: 200,
                duration: 600,
                easing: "ease-in-sine",
                delay: 100,
            });
            new this.$wow.WOW().init({
                boxClass: "wow",
                animateClass: "animate__animated",
                offset: 0,
                mobile: true,
                live: true,
            });
        });
    },
    mounted() {
        function show() {
            let dom = document.getElementsByClassName("hb7Box");
            if (dom[0].getBoundingClientRect().top < 500) {
                let toRight = document.getElementsByClassName("toRight");
                toRight[0].style.width = "100%";
                window.removeEventListener("scroll", show);
            }
        }
        window.addEventListener("scroll", show);
        this.start();
    },
    methods: {
        showMove(i) {
            switch (i) {
                case 1:
                    this.showIcon1 = 2;
                    break;
                case 2:
                    this.showIcon2 = 2;
                    break;
                case 3:
                    this.showIcon3 = 2;
                    break;
                case 4:
                    this.showIcon4 = 2;
                    break;
                case 5:
                    this.showIcon5 = 2;
                    break;
                case 6:
                    this.showIcon6 = 2;
                    break;
                case 7:
                    this.showIcon7 = 2;
                    break;
                case 8:
                    this.showIcon8 = 2;
                    break;
                case 9:
                    this.showIcon9 = 2;
                    break;
                case 10:
                    this.showIcon10 = 2;
                    break;
            }
        },
        showLea(i) {
            switch (i) {
                case 1:
                    this.showIcon1 = 1;
                    break;
                case 2:
                    this.showIcon2 = 1;
                    break;
                case 3:
                    this.showIcon3 = 1;
                    break;
                case 4:
                    this.showIcon4 = 1;
                    break;
                case 5:
                    this.showIcon5 = 1;
                    break;
                case 6:
                    this.showIcon6 = 1;
                    break;
                case 7:
                    this.showIcon7 = 1;
                    break;
                case 8:
                    this.showIcon8 = 1;
                    break;
                case 9:
                    this.showIcon9 = 1;
                    break;
                case 10:
                    this.showIcon10 = 1;
                    break;
            }
        },
        // 停止随机显示
        stop(item) {
            clearInterval(this.timer);
            this.hoverItem = item;
        },
        // 开启随机显示
        start() {
            // 随机显示一项
            this.timer = setInterval(() => {
                function sjs(n) {
                    let num = Math.floor(Math.random() * 8) + 1;
                    if (num === n) return sjs(n);
                    return num;
                }
                this.hoverItem = sjs(this.hoverItem);
            }, 4000);
        },
        async getData() {
            const { data } = await axios.get("api/project/homePhone");
            this.list = data;
        },
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            // 点击后的回调
            this.$emit("callBack");
        },
    },
};
</script>

<style lang="less" scoped>
.hc17Box {
    width: 100%;
    margin-top: 78px;
    .hbBox1 {
        width: 100%;
        height: 575px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 100%;

        .h1Box {
            max-width: 1280px;
            height: 100%;
            margin: 0 auto;
            //   overflow: hidden;
            position: relative;
            .hbText {
                width: 542px;
                height: 120px;
                text-align: center;
                padding-top: 200px;
                padding-left: 150px;
                white-space: nowrap;
                .ht1 {
                    width: 100%;
                    height: 75px;
                    font-size: 41px;
                    color: white;
                    span {
                        color: #ffeb46;
                        font-weight: bold;
                    }
                }
                .ht2 {
                    width: 100%;
                    height: 45px;
                    line-height: 45px;
                    color: #cdcecf;
                    font-size: 14px;
                }
            }
            .hbImg {
                width: 483px;
                height: 588px;
                position: absolute;
                right: 0;
                top: 50px;
                img {
                    width: 100%;
                    height: 100%;
                    z-index: 5;
                }
            }
        }
    }
    .hbBox2 {
        width: 100%;
        height: 860px;
        overflow: hidden;
        .hb2Top {
            width: 100%;
            height: 84px;
            text-align: center;
            line-height: 84px;
            font-size: 36px;
            margin-top: 100px;
            font-weight: bold;
            color: #60646a;
        }
        .hb2List {
            max-width: 1200px;
            height: 668px;
            margin: 0 auto;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                background: url("http://iv.okvu.cn/vugw/img/icon_92.png") no-repeat bottom center;
                .hb2Li {
                    width: 399px;
                    height: 568px;
                    border-radius: 10px;
                    background: #f8f8f8 url("http://iv.okvu.cn/vugw/img/icon_66.png") no-repeat bottom right;
                    .hlBox {
                        width: 90%;
                        height: 80%;
                        margin: 0 auto;
                        margin-top: 50px;
                        .hlb1 {
                            width: 100%;
                            height: 80px;
                            line-height: 80px;
                            font-size: 18px;
                            font-weight: bold;
                            color: gray;
                        }
                        .hlb2 {
                            width: 100%;
                            height: 60px;
                            font-size: 15px;
                            color: gray;
                            margin-top: 10px;
                        }
                    }
                }
                .hb2Li:nth-child(2) {
                    background-color: #fff;
                }
            }
        }
    }
    .hbBox3 {
        width: 100%;
        height: 927px;
        overflow: hidden;
        .hb3Top {
            width: 100%;
            height: 149px;
            text-align: center;
            .h3t1 {
                width: 100%;
                height: 74px;
                line-height: 84px;
                font-size: 36px;
                font-weight: bold;
                color: #60646a;
            }
            .h3t2 {
                width: 100%;
                height: 40px;
                font-size: 14px;
                color: gray;
            }
        }
        .hb3Bg {
            width: 100%;
            // height: 745px;
            overflow: hidden;
            background-color: #f5faff;
            .hb3List {
                max-width: 1280px;
                height: 592px;
                margin: 0 auto;
                margin-top: 50px;
                margin-bottom: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .hb3Li {
                        width: 365px;
                        height: 100%;
                        background-color: white;
                        border-radius: 25px;
                        .h3Box {
                            width: 90%;
                            height: 90%;
                            margin: 0 auto;
                            margin-top: 7%;
                            .h31 {
                                width: 100%;
                                height: 64px;
                                line-height: 64px;
                                font-size: 24px;
                                text-align: center;
                                font-weight: 900;
                                color: #60646a;
                            }
                            .h32 {
                                width: 100%;
                                min-height: 322px;
                                margin-top: 20px;
                                dl {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;

                                    flex-wrap: wrap;
                                    dt {
                                        width: 113px;
                                        height: 161px;
                                        margin-left: 35px;
                                        .dtImg {
                                            width: 113px;
                                            height: 113px;
                                            border-radius: 50%;
                                            overflow: hidden;
                                            .aiIcon {
                                                display: block;
                                                margin: 0 auto;
                                                margin-top: 33px;
                                            }
                                        }
                                        .dtp {
                                            width: 100%;
                                            height: 48px;
                                            line-height: 48px;
                                            font-size: 14px;
                                            text-align: center;
                                        }
                                    }
                                    dt:nth-child(1) {
                                        .dtImg {
                                            background-color: #aca7e2;
                                        }
                                    }
                                    dt:nth-child(2) {
                                        .dtImg {
                                            background-color: #ffdb7a;
                                        }
                                    }
                                    dt:nth-child(3) {
                                        .dtImg {
                                            background-color: #7cc8ec;
                                        }
                                    }
                                    dt:nth-child(4) {
                                        .dtImg {
                                            background-color: #8fe162;
                                        }
                                    }
                                    dt:nth-child(5) {
                                        .dtImg {
                                            background-color: #a6baca;
                                        }
                                    }
                                    .dt3 {
                                        width: 100%;
                                        height: 113px;
                                        .dt3Img {
                                            width: 113px;
                                            height: 113px;
                                            border-radius: 50%;
                                            overflow: hidden;
                                            float: left;
                                            .aiIcon {
                                                display: block;
                                                margin: 0 auto;
                                                margin-top: 33px;
                                            }
                                        }
                                        .dt3p {
                                            width: 112px;
                                            height: 78%;
                                            margin-left: 30px;
                                            float: left;
                                            font-size: 14px;
                                            margin-top: 30px;
                                        }
                                    }
                                    .dt3:nth-child(1) {
                                        .dt3Img {
                                            background-color: #aca7e2;
                                        }
                                    }
                                    .dt3:nth-child(2) {
                                        margin-top: 30px;
                                        .dt3Img {
                                            background-color: #7cc8ec;
                                        }
                                    }
                                    .dt3:nth-child(3) {
                                        margin-top: 30px;
                                        .dt3Img {
                                            background-color: #ffdb7a;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .hbBox4 {
        width: 100%;
        height: 570px;
        overflow: hidden;
        .hb4Top {
            width: 100%;
            height: 149px;
            text-align: center;
            .h4t1 {
                width: 100%;
                height: 74px;
                line-height: 84px;
                font-size: 36px;
                font-weight: bold;
                color: #60646a;
            }
            .h4t2 {
                width: 100%;
                height: 65px;
                font-size: 14px;
                color: gray;
            }
        }
        .hb4List {
            max-width: 915px;
            height: 416px;
            margin: 0 auto;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                .hb4Li {
                    width: 143px;
                    height: 173px;
                    border-radius: 20px;
                    background-color: #eee;
                    margin-left: 10px;
                    cursor: pointer;
                    transition: 0.5s;
                    .h4lBox {
                        width: 90%;
                        height: 80%;
                        margin: 0 auto;
                        margin-top: 10%;
                        .hb1 {
                            width: 55%;
                            height: 64px;
                            margin: 0 auto;
                            margin-top: 30px;
                            position: relative;
                            overflow: hidden;
                            img {
                                display: block;
                                margin-left: 20px;
                            }
                            .h1Img {
                                position: absolute;
                                top: -532px;
                                left: -756px;
                            }
                            .h1Img12 {
                                width: 1700px;
                                height: 2600px;
                                position: absolute;
                                top: -621px;
                                left: -17px;
                            }
                            .h1Img2 {
                                position: absolute;
                                top: -1050px;
                                right: -328px;
                            }
                            .h1Img22 {
                                position: absolute;
                                top: -1116px;
                                right: -328px;
                            }
                            .h1Img3 {
                                position: absolute;
                                top: -531px;
                                left: -590px;
                            }
                            .h1Img32 {
                                width: 1700px;
                                height: 2680px;
                                position: absolute;
                                top: -638px;
                                left: -85px;
                            }
                            .h1Img4 {
                                position: absolute;
                                top: -532px;
                                right: -459px;
                            }
                            .h1Img42 {
                                position: absolute;
                                top: -609px;
                                left: -145px;
                            }
                            .h1Img5 {
                                position: absolute;
                                top: -680px;
                                left: -10px;
                            }
                            .h1Img52 {
                                width: 1700px;
                                height: 2600px;
                                position: absolute;
                                top: -617px;
                                left: -229px;
                            }
                            .h1Img6 {
                                position: absolute;
                                top: -531px;
                                left: -672px;
                            }
                            .h1Img62 {
                                width: 2000px;
                                height: 3000px;
                                position: absolute;
                                top: -720px;
                                left: -343px;
                            }
                            .h1Img7 {
                                position: absolute;
                                top: -525px;
                                right: 1px;
                            }
                            .h1Img72 {
                                width: 1700px;
                                height: 2600px;
                                position: absolute;
                                top: -618px;
                                left: -356px;
                            }
                            .h1Img8 {
                                position: absolute;
                                top: -680px;
                                left: -90px;
                            }
                            .h1Img82 {
                                width: 1613px;
                                height: 2600px;
                                position: absolute;
                                top: -617px;
                                left: -402px;
                            }
                            .h1Img9 {
                                position: absolute;
                                top: -532px;
                                left: -968px;
                            }
                            .h1Img92 {
                                width: 1750px;
                                height: 2700px;
                                position: absolute;
                                top: -645px;
                                left: -511px;
                            }
                            .h1Img10 {
                                position: absolute;
                                top: -536px;
                                left: -896px;
                            }
                            .h1Img102 {
                                width: 1600px;
                                height: 2600px;
                                position: absolute;
                                top: -619px;
                                left: -538px;
                            }
                        }
                        .hb2 {
                            width: 100%;
                            height: 14px;
                            font-size: 14px;
                            margin-top: 20px;
                            text-align: center;
                        }
                    }
                }
                .hb4Li:hover {
                    background-color: skyblue;
                    transform: translateY(-10px);
                }
            }
        }
    }
    .hbBox5 {
        width: 100%;
        height: 820px;
        overflow: hidden;
        .hb5Top {
            width: 100%;
            height: 76px;
            text-align: center;
            line-height: 76px;
            margin-top: 30px;
            font-size: 36px;
            font-weight: bold;
            color: #60646a;
        }
        .hb5List {
            max-width: 1280px;
            height: 596px;
            margin: 0 auto;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                .hb5Li {
                    width: 389px;
                    height: 278px;
                    border-radius: 10px;
                    overflow: hidden;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    position: relative;
                    cursor: pointer;
                    & > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: 0.5s;
                    }
                    .hb5Mask {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        background: rgba(0, 0, 0, 0.5);
                        opacity: 0;
                        transform: translateY(30px);
                        border-radius: 10px;
                        transition: 0.5s;
                        .hlCode {
                            width: 120px;
                            height: 120px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            object-fit: contain;
                            // border-radius: 5px;
                            // margin: 0 auto;
                            // margin-top: 85px;
                            // display: block;
                            border-radius: 10px;
                        }
                        .hb5Text {
                            width: 100%;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            color: white;
                            margin-top: 20px;
                        }
                    }
                }
                .hb5Li:hover {
                    img {
                        transform: scale(1.1);
                    }
                    .hb5Mask {
                        transform: translateY(0px);
                        opacity: 1;
                    }
                }
            }
        }
    }
    .hbBox6 {
        width: 100%;
        height: 824px;
        overflow: hidden;
        background: #f1faff;
        position: relative;
        .hb6Sun {
            position: absolute;
            transition: margin 0.5s;
            top: 215px;
            left: 100px;
            animation: zy-dx2 15s infinite linear;
        }
        @keyframes zy-dx2 {
            0% {
                transform: translate(0, 0);
            }
            25% {
                transform: translate(25px, -20px);
            }
            50% {
                transform: translate(50px, 0px);
            }
            75% {
                transform: translate(25px, -20px);
            }
            100% {
                transform: translate(0, 0);
            }
        }
        .hb6Yun {
            position: absolute;
            transition: margin 0.5s;
            top: 215px;
            right: 100px;
            animation: zy-dx1 20s infinite linear;
        }
        @keyframes zy-dx1 {
            0% {
                transform: translateX(0);
            }
            25% {
                transform: translateX(50px);
            }
            50% {
                transform: translateX(0px);
            }
            75% {
                transform: translateX(50px);
            }
            100% {
                transform: translateX(0);
            }
        }
        .h6Top {
            position: absolute;
            left: 0;
        }
        .hb6Top {
            width: 100%;
            height: 76px;
            line-height: 76px;
            text-align: center;
            font-size: 36px;
            position: relative;
            font-weight: bold;
        }
        .hb6Box {
            max-width: 1207px;
            height: 391px;
            margin: 0 auto;
            margin-top: 230px;
            background: url("http://iv.okvu.cn/vugw/img/icon_31.png") no-repeat bottom center;
            position: relative;
            ul {
                width: 100%;
                height: 100%;
                .hb6Li {
                    width: 60px;
                    height: 86px;
                    cursor: pointer;
                    .hl1 {
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        font-size: 15px;
                        font-weight: bold;
                    }
                    .hl2 {
                        width: 46px;
                        height: 46px;
                        border-radius: 50%;
                        background: url("http://iv.okvu.cn/vugw/img/icon_42.png");
                        .h2Img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }
                    .hb6T {
                        opacity: 0;
                        transition: 0.5s;
                        position: absolute;
                        top: -88px;
                        right: 50%;
                        z-index: 99;
                        width: 386px;
                        margin-right: -193px;
                        padding: 10px 30px;
                        border: 2px solid #b6d8ec;
                        border-radius: 50px;
                        background: #fff;
                        box-sizing: border-box;
                        box-shadow: -8px 8px 0px #fff;
                        pointer-events: none;
                    }
                }
                .hb6Li:hover {
                    .hl2 {
                        box-shadow: 0 3px 10px #93d7f4;
                        background-color: #00aaff;
                    }
                    .hb6T {
                        opacity: 1;
                    }
                }
                .hb6Li:nth-child(1) {
                    position: absolute;
                    top: 15px;
                    left: 265px;
                }
                .hb6Li:nth-child(2) {
                    position: absolute;
                    top: -55px;
                    left: 460px;
                }
                .hb6Li:nth-child(3) {
                    position: absolute;
                    top: -25px;
                    right: 420px;
                }
                .hb6Li:nth-child(4) {
                    position: absolute;
                    top: 20px;
                    right: 210px;
                }
            }
        }
        .h6l1 {
            position: absolute;
            left: 80px;
            bottom: 100px;
        }
        .h6l2 {
            position: absolute;
            left: -60px;
            bottom: -50px;
        }
        .h6r1 {
            position: absolute;
            right: -20px;
            bottom: 0px;
            z-index: 5;
        }
        .hb6B {
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 1920px;
            height: 95px;
            border-top: 2px solid #b3d7ec;
            border-bottom: 2px solid #b3d7ec;
            background: #fff;
            margin-left: -960px;
        }
    }
    .hbBox7 {
        width: 100%;
        height: 540px;
        overflow: hidden;
        // background: url('http://iv.okvu.cn/vugw/img/icon_51.png');
        // background-size: 100%;
        position: relative;
        margin-top: -126px;
        z-index: 9;
        .hb7Top {
            width: 100%;
            height: 76px;
            line-height: 76px;
            text-align: center;
            font-size: 36px;
            margin-top: 20px;
            font-weight: bold;
            color: white;
        }
        .hb7Bg {
            width: 135%;
            left: -19%;
            // height: 100%;
            height: 600px;
            z-index: 5;
            position: absolute;
        }
        .hb7Bg2 {
            width: 100%;
            height: 100%;
            z-index: -1;
            position: absolute;
            top: 0px;
            left: 0px;
        }
        .hb7Box {
            // max-width: 1280px;
            width: 89%;
            height: 100%;
            margin: 0 auto;
            margin-top: 150px;
            position: relative;
            z-index: 6;
            ul {
                transition: width 2s;
                width: 0%;
                height: 84px;
                background: url("http://iv.okvu.cn/vugw/img/icon_53.png") no-repeat center;
                background-size: 100% 100%;
                .hb7Li {
                    width: 60px;
                    height: 75px;
                    cursor: pointer;
                    position: relative;
                    i {
                        font-size: 40px;
                        line-height: 62px;
                        color: #3bb6eb;
                    }
                    .hl1 {
                        width: 100%;
                        height: 50px;
                        text-align: center;
                    }
                    .hl2 {
                        width: 100%;
                        height: 25px;
                        line-height: 25px;
                        font-size: 15px;
                        font-weight: bold;
                    }

                    .hlT {
                        width: 268px;
                        height: 68px;
                        border-radius: 30px;
                        background-color: #fff;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        position: absolute;
                        top: -35px;
                        left: -105px;
                        opacity: 0;
                        transition: 0.5s;
                        .ht1 {
                            width: 63px;
                            height: 63px;
                            border-radius: 50%;
                            float: left;
                            margin-top: 2px;
                            margin-left: 2px;
                            background-color: #eee;
                            text-align: center;
                        }
                        .ht2 {
                            width: 180px;
                            height: 51px;
                            float: left;
                            font-size: 14px;
                            margin-left: 10px;
                            margin-top: 10px;
                        }
                    }
                    .hlT::after {
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        content: " ";
                        width: 17px;
                        height: 5px;
                        margin-left: -3px;
                        background: url("http://iv.okvu.cn/vugw/img/icon_54.png") no-repeat center;
                    }
                    .xia {
                        top: 40px;
                        &::after {
                            top: -5px;
                            transform: rotate(180deg);
                        }
                    }
                }
                .hb7Li:hover {
                    .hlT {
                        // opacity: 1;
                    }
                }
                .hb7Li::before {
                    position: absolute;
                    bottom: -26px;
                    left: 50%;
                    content: " ";
                    width: 12px;
                    height: 12px;
                    margin-left: -6px;
                    border: 4px solid #fff;
                    border-radius: 50%;
                    box-sizing: border-box;
                }
                .hb7Li:nth-child(1) {
                    position: absolute;
                    top: -16%;
                    left: 8%;
                }
                .hb7Li:nth-child(2) {
                    position: absolute;
                    top: 12%;
                    left: 21%;
                }
                .hb7Li:nth-child(3) {
                    position: absolute;
                    top: -16%;
                    left: 33%;
                }
                .hb7Li:nth-child(4) {
                    color: black;
                    position: absolute;
                    top: 12%;
                    left: 43%;
                }
                .hb7Li:nth-child(5) {
                    position: absolute;
                    top: -16%;
                    left: 54%;
                }
                .hb7Li:nth-child(6) {
                    color: black;
                    position: absolute;
                    top: 11%;
                    left: 65%;
                }
                .hb7Li:nth-child(7) {
                    position: absolute;
                    top: -17%;
                    right: 19%;
                }
                .hb7Li:nth-child(8) {
                    color: black;
                    position: absolute;
                    top: 11%;
                    right: 5%;
                }
                .hb7Li:nth-child(2)::before,
                .hb7Li:nth-child(4)::before,
                .hb7Li:nth-child(6)::before,
                .hb7Li:nth-child(8)::before {
                    position: absolute;
                    top: -26px;
                    left: 50%;
                    content: " ";
                    width: 12px;
                    height: 12px;
                    margin-left: -6px;
                    border: 4px solid #fff;
                    border-radius: 50%;
                    box-sizing: border-box;
                }
            }
        }
    }
}
</style>
